.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

tr.row-dragging {
  width: 100%;
  display: flex;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
  margin-right: 55px;
}
.row-dragging td:nth-child(1),
.row-dragging td:nth-child(2) {
  visibility: visible;
}


/*  Styles related to tabs dropdown created by Antd in compliance admin component  */
.ant-tabs-dropdown .ant-tabs-dropdown-menu {
  padding: 0;
  max-height: 500px;
  width: 110px;
}

.ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item {
  background: #dbeef5f7;
  padding: 0.7rem 0.5rem;
}

.ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item:not(:last-child) { 
  border-bottom: 1px solid #2f4960;
}
