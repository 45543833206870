body {
  background-color: #4c4c4c;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  font-family: arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  line-height: 1.5715;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-popover-inner {
  background-color: #2f4960;
}

.ant-popover-arrow-content {
  background-color: #2f4960;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
